<template
  ><div>
    <div class="not-found">
      <section class="section">
        <div class="container">
          <div class="columns is-centered is-vcentered">
            <div class="column is-four-fifths">
              <div class="card card-not-found">
                <h1
                  class="h1-not-found is-size-1 has-text-white text-uppercase"
                >
                  <b>oops! algo deu errado</b>
                </h1>
                <p
                  class="is-size-5 has-text-white text-uppercase has-text-centered paragraph"
                >
                  O endereço que você digitou não pode ser encontrado
                </p>
                <a
                  @click="$router.replace({ path: '/' })"
                  class="btn btn-orange-solid"
                  >voltar para a home
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- <Footer /> -->
  </div>
</template>
<script>
export default {
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "404 - Página não encontrada",
    meta: [
      {
        name: "description",
        content: "Ops! Algo inesperado ocorreu"
      },
      { name: "robots", content: "noindex, nofollow" }
    ]
  }
};
</script>
<style scoped>
@import url(./index.css);
</style>
